/*
 * NOTE Often times you'll want to remember IP detects locally for the user.
 * Use this to do that: https://github.com/rubystarashe/nuxt-vuex-localstorage
 */

// const g = {
//     'GBP': "TEFLORG",
//     'USD': "TEFLUS",
//     'EUR': "IRELAND",
//     'CAD': "CANADA",
//     'AUD': "AUSTRALIA",
//     'ZAR': "SOUTHAFRICA"
//   }

// Define state default
const getDefaultState = () => {
    return {
        // ip: "",
        detectedCountry: "",
        detectedCity: "",
        detectedRegion: "",
        detectedCurrency: "",
        detectedTimezone: "",
        defaultSaved: false,
        isRegionReady: false,
        hours: {
            open:'',
            close:'',
        },
        // userRegion: "en", // This is where you would set the user defined country (like on button click)
        version: 1, // Used by nuxt-vuex-localstorage to reset all users location
    }
  }

export const state = () => getDefaultState()

// Define mutations
export const mutations = {
    SET_GEOLOCATION(state, data) {
        // console.log('SET_GEOLOCATION: ',data);
        // console.log('Setting location: ',state,data)
        // If a contry code provided, then just set the user defined country
        if (typeof data === "string") {
            // console.log('String: ',data)
            // state.userRegion = data
        } else {
            // Else set entire geolocation object
            // state.ip = data.ip || state.ip
            state.detectedCountry = data.detectedCountry || state.detectedCountry
            state.detectedCity = data.detectedCity || state.detectedCity
            state.detectedTimezone = data.detectedTimezone || state.detectedTimezone
            state.detectedRegion = data.detectedRegion || state.detectedRegion
            state.detectedCurrency = data.detectedCurrency || state.detectedCurrency
            // From geolocation module; Need THIS keyword to access global state and update cart
            // this.state.cart.defaultPriceLabel = g[data.detectedCurrency] || 'TEFLUS';
            // We don't want to overwrite this
            // state.userRegion = data.userRegion || state.userRegion
        }
        // console.log('New region: ',state.userRegion)
    },
    SET_HOURS(state, data) {
        console.log('SET_HOURS: ',data);
        state.hours = data
    },
    SET_DEFAULT(state,data) {
        console.log('SET_DEFAULT: ',data);
        state.defaultSaved = data
    },
    SET_REGION_READY(state, status) {
        console.log('SET_REGION_READY: ',status);
        state.isRegionReady = status;
    }
}

// Getters
export const getters = {
    // Convert country code to a custom region
    // NOTE You can access this getter using this syntax: this.$store.getters["geolocation/region"]
    region(state, getters, rootState) {
        const userRegion = rootState.user?.userRegion || 'default';
    
        // ✅ Prefer user-selected region
        if (userRegion && userRegion !== 'default') {
            return userRegion;
        }

        // Default region
        let region = "en-us"

        // Other regions (should expand these regions and country codes as needed)
        // SEE all two letter country codes here: https://www.iban.com/country-codes
        const europeRegion = ['AL','AD','AM','AT','BY','BE','BA','BG','CH','CY','CZ','DE','DK','EE','ES','FO','FI','FR','GE','GI','GR','HU','HR','IE','IS','IT','LI','LT','LU','LV','MC','MK','MT','NO','NL','PL','PT','RO','RS','RU','SE','SI','SK','SM','TR','UA','VA',]
        const americaRegion = ["US"]
        const canadaRegion = ["CA"]
        const australiaRegion = ["AU","NZ"]
        const ukRegion = ["GB"]
        const asiaRegion = ["CN"]
        const africaRegion = ["ZA"]

        switch (true) {
            case europeRegion.includes(state.detectedCountry):
                region = "ie"
                break

            case americaRegion.includes(state.detectedCountry):
                region = "en-us"
                break

            case canadaRegion.includes(state.detectedCountry):
                region = "en-us"
                break

            case australiaRegion.includes(state.detectedCountry):
                region = "en-us"
                break

            case asiaRegion.includes(state.detectedCountry):
                region = "en-us"
                break
            
            case africaRegion.includes(state.detectedCountry):
                region = "en"
                break

            case ukRegion.includes(state.detectedCountry):
                region = "en"
                break
        }

        return region

        // return state.detectedRegion
    },
    detectedCountry: (state) => {
        return state.detectedCountry
    },
    detectedTimezone: (state) => {
        return state.detectedTimezone
    },
    detectedCity: (state) => {
        return state.detectedCity
    },
    detectedRegion: (state) => {
        return state.detectedRegion
    },
    detectedCurrency: (state) => {
        return state.detectedCurrency
    },
    getHours: (state) => {
        return state.hours
    },
    defaultSaved: (state) => {
        return state.defaultSaved
    },
    isRegionReady: (state) => {
        console.log('Is region ready?: ',state.isRegionReady);
        return state.isRegionReady
    },
}

// Actions
export const actions = {
    updateRegion({commit}, data) {
        commit('SET_GEOLOCATION', data)
    },
    setHours({commit}, data) {
        commit('SET_HOURS', data)
    }
}