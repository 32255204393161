
import { mapGetters } from 'vuex'
export default {
  props: {
    region: {
      type: String,
      default: '',
    },
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      // region: 'user/region',
      detectedRegion: 'geolocation/region',
      userRegion: 'user/region',
    }),
    regionVar() {
      console.log('COMPUTING REGION FOR MENU TO SHOW...', this.$props.region, this.detectedRegion, this.userRegion);
      const regionVar = this.$props.region
      // const validRegions = ['ca', 'au', 'za']; // Add regions to this as the pages are completed
      const validRegions = [];

      if (validRegions.includes(regionVar)) {
        return '/' + regionVar;
      }
      
      return '/en-us';
    },
  },
}
