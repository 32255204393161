import productData from '@/data/products.json'; // Adjust path as needed

const specificRegions = ['us', 'ie', 'za', 'au', 'ca'];

export default (_, inject) => {
  inject('regionUrl', function (productId, region = 'uk') {
    const r = region?.toLowerCase() || 'uk';
    const key = specificRegions.includes(r) ? `en_${r}` : 'en';

    console.log(`🌍 Resolving region URL for productId: ${productId}, region: ${r}, key: ${key}`);

    const product = productData.find(p => p.id === Number(productId));

    if (!product) {
      // console.warn(`⚠️ Product not found for ID: ${productId}`);
      return '/';
    }

    if (!product.page) {
      // console.warn(`⚠️ Product has no 'page' property:`, product);
      return '/';
    }

    const page = product.page[key] || product.page.en;

    if (!page) {
      // console.warn(`⚠️ No URL found for region key: '${key}' — falling back to root`);
      return '/';
    }

    console.log(`✅ Resolved URI for product ${productId} in region '${r}': ${page.uri}`);
    return page.uri || '/';
  });
};
